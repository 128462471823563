import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Relocation = () => (
  <Layout>
    <SEO title="Relocation" />
    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Relocation</h1>
        <p>
          Gray Hawk's right-of-way and relocation agents have a strong knowledge
          base of Public Agency and Private business processes relative to
          Department of Transportation, electric transmission, midstream &
          pipeline, telecommunications, and renewable energy projects. Our
          specialized professionals provide a unique skill set with in-depth
          knowledge of the Uniform Relocation Assistance and Real Property
          Acquisition Policies Act (the Uniform Act). Gray Hawk's relocation
          assistance agents are extremely adept at working through Uniform Act
          processes on behalf of our clients.
        </p>
      </div>
    </div>
  </Layout>
)

export default Relocation
